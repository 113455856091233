import React, { useState } from 'react'
import PageHeader from '../../components/community/PageHeader'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import MapGrid from '../../components/community/MapGrid'
import CTA from '../../components/common/CTA'

const CommunityMapsPage = ({ data }) => {
  const [activeCategory, setActiveCategory] = useState('Explore all')

  return (
    <Layout
      title="Community Maps - Curated best-practice maps"
      meta={{
        description:
          'Curated best-practice maps for you to explore, analyze and share. Step into Atlas to explore, analyze and share geospatial data.',
        title: 'Community Maps - Curated best-practice maps',
        type: 'website'
      }}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <MapGrid maps={data?.maps?.nodes} activeCategory={activeCategory} />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    maps: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___created] }
      filter: { fileAbsolutePath: { regex: "/community-maps/" } }
      limit: 100
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          link
          tag
          featured
          trending
          newest
          created
          creator
          description
        }
      }
    }
  }
`

export default CommunityMapsPage
